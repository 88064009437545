import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "configure-app-authentication"
    }}>{`Configure App Authentication`}</h1>
    <hr></hr>
    <p>{`To configure your mobile app or template's authentication, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps section in the left-side menu. Select the mobile app or template you wish to edit.`}</li>
      <li parentName="ol">{`Click on 'Edit' next to the Data Provider. This action will take you to the Edit Data Provider page. Navigate to the Authentication section.`}</li>
    </ol>
    <p><img alt="CMS Authentication" src={require("./images/authentication.png")} />{` `}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Set Credential Storage from the dropdown menu option:`}
        <ul parentName="li">
          <li parentName="ul">{`'Automatic' will save users' credentials for their next login.`}</li>
          <li parentName="ul">{`'Optional' will provide patrons the option to save their credentials for their next login.`}</li>
          <li parentName="ul">{`'Disabled' will prevent your app from saving their credentials.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Set the desired Username Format:`}
        <ul parentName="li">
          <li parentName="ul">{`'Alphanumeric' will accept both numbers and letters.`}</li>
          <li parentName="ul">{`'Numeric' will only accept numbers.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Specify how linked accounts should be handled:`}
        <ul parentName="li">
          <li parentName="ul">{`If 'Retain after last primary logout' is enabled, your app will log in all linked accounts when the primary account logs in.`}</li>
          <li parentName="ul">{`If 'Retain after last primary logout' is not enabled, the primary account will need to log in any linked accounts after logging in.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Specify the Password Format in the dropdown menu option:`}
        <ul parentName="li">
          <li parentName="ul">{`'PIN' will only accept numbers for authentication.`}</li>
          <li parentName="ul">{`'Password' will accept both numbers and letters.`}</li>
          <li parentName="ul">{`'None' will only require the patron's username to login.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Set the Username Processing in the dropdown menu option. This details which barcode characters to remove from the username as part of the authentication process. `}<em parentName="li">{`Please note that any removed characters from the barcode will still show within your app, as they are part of the barcode, but will be stripped out on the server before authenticating with your ILS/LMS`}</em>{`:`}
        <ul parentName="li">
          <li parentName="ul">{`'As Is' will maintain the barcode exactly as it is read by the scanner.`}</li>
          <li parentName="ul">{`'Codabar: Drop First & Last Character' will scan the codabar barcode but will drop the first and last characters before sending it to the ILS/LMS for authetication.`}</li>
          <li parentName="ul">{`'Codabar: Drop First Character' will scan the codabar barcode but will drop the first character before sending it to the ILS/LMS for authentication.  `}</li>
          <li parentName="ul">{`'Codabar: Drop Last Character' will scan the codbar barcode but will drop the last character before sending it to the ILS/LMS for authentication.  `}</li>
          <li parentName="ul">{`'Drop 1st Character' will scan the barcode but will drop the first character before sending to the ILS/LMS for authetication. `}</li>
          <li parentName="ul">{`'Drop 1st & 2nd Character' will scan the barcode but will drop the first and last character before sending to the ILS/LMS for authentication. `}</li>
          <li parentName="ul">{`'NEC 16 Drop' will drop the last two digits (check digits) of the 16 digit National Entitlement Card number. `}</li>
        </ul>
      </li>
      <li parentName="ol">{`Set Barcode Processing in the dropdown menu option:`}
        <ul parentName="li">
          <li parentName="ul">{`'Full' will use the entire barcode string.`}</li>
          <li parentName="ul">{`'Trimmed' will remove extraneous characters as configured in your ILS/LMS. `}</li>
        </ul>
      </li>
      <li parentName="ol">{`Once you've completed the settings, click 'Save' to save the changes.`}</li>
      <li parentName="ol">{`When directed back to the Mobile App screen, click 'Save' at the bottom to update your app.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      